<template>
  <div class="intro-page">
    <div class="intro-container">
      <h1 class="intro-title">臻鼎科技集团EAP干事核心素养测评</h1>
      <!-- <h1 class="intro-title">调查问卷</h1> -->
      <div class="content1">亲爱的同事：<br /></div>

      <div class="content">
        您好！为更好地推进员工心理健康支持工作，现面向全体职工公开招募EAP（员工帮助计划）干事团队成员。本问卷旨在了解您对参与此项工作的主观意愿及潜在需求，所有回答将严格保密，仅用于选拔参考。问卷填写约需5-10分钟，请根据实际情况勾选最符合的选项。感谢您的支持与参与！
      </div>

      <button class="start-button" @click="startAssessment">开始测评</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  methods: {
    startAssessment() {
      const userId = localStorage.getItem("userId");
      if (!userId || userId == "undefined") {
        Toast("请先登录");
        setTimeout(() => {
          this.$router.push("/cp/zhneding/login");
        }, 1000);
        return;
      }
      // 跳转到测评页面
      this.$router.push("/CP/zhending/answer");
    },
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  /* background-color: #f9f9f9; */
  padding: 20px;
  box-sizing: border-box;
  background-image: url("https://lesson.iapeap.com/images/cp/wuzi.png");
  background-size: 100% 100%;
  /* height: 100vh; */
}

.intro-container {
  max-width: 720px;
  background: rgb(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* 标题样式 */
.intro-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

/* 正文内容样式 */
.intro-text {
  line-height: 1.8;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}

/* 按钮样式 */
.start-button {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.start-button:hover {
  background-color: #5ba9a1;
}

/* 响应式处理 */
@media (max-width: 768px) {
  .intro-container {
    padding: 20px;
  }
  .intro-title {
    font-size: 1em;
  }
  .start-button {
    padding: 8px 16px;
  }
}
.content {
  text-align: left;
  text-indent: 20px;
}
.content1 {
  text-align: left;
  margin-bottom: 20px;
}
</style>
